<template>
  <div class="applicationBtn">
    <!-- 应用按钮 -->
    <div class="btnItem" @click="jumpPage('https://zhenxinyihu.com/s/qFuaeYTQ')">
      <form-outlined />
      <span>报名</span>
    </div>
  </div>
</template>
<script setup>
import { FormOutlined } from "@ant-design/icons-vue";
const jumpPage = (e) => {
  window.open(e, "_blank");
}
</script>
<style lang="less" scoped>
.applicationBtn {
  position: fixed;
  right: 10px;
  top: 50%;
  background-color: #fff;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow:0 0 10px rgba(0, 0, 0, 0.3);

  .btnItem {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: #dc3434;
    cursor: pointer;
  }
}
</style>
