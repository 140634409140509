<template>
  <div class="trainDetail">
    <div class="container">
      <div class="detailInfo">
        <div class="infoBox">
          <img :src="info.imgSrc" />
          <div class="infoRight">
            <div class="infoTitle">课程名称：{{ info.title }}</div>
            <div class="infoCenter">
              <span>在线咨询</span>
            </div>
            <div class="infoRigtBot">
              <div class="rightItem">
                <span>【课程周期】：</span>
                <span>{{ info.cycle }}课时</span>
              </div>
              <div class="rightItem">
                <span>【上课时间】：</span>
                <span>{{ info.classTime }}点</span>
              </div>
              <div class="rightItem">
                <span>【授课形式】：</span>
                <span>{{ info.classType }}课时</span>
              </div>
            </div>
          </div>
          <div class="btn" @click="gotoHref('https://zhenxinyihu.com/s/qFuaeYTQ')">
            立即报名
          </div>
        </div>
      </div>
      <div class="detailImg">
        <img :src="imgUrl" alt="" />
      </div>
    </div>
    <applicationBtn></applicationBtn>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import applicationBtn from "@/components/common/applicationBtn.vue";
import { gotoHref } from "@/hooks";
const route = useRoute();
const type = route.query.type;
// type 1-评估师  2-护理员 3-咨询师
const info = computed(() => {
  if (type == "1") {
    return {
      title: "老年人能力评估师",
      cycle: "42/90",
      classTime: "9-16",
      classType: "线上面授",
      imgSrc: require("@/assets/images/train/lnr.jpg")
    };
  } else if (type == "2") {
    return {
      title: "养老护理员",
      cycle: "42/90",
      classTime: "9-16",
      classType: "线上面授",
      imgSrc: require("@/assets/images/train/ylhly.png")
    };
  } else {
    return {
      title: "康复辅助技术咨询师",
      cycle: "42/90",
      classTime: "9-16",
      classType: "线上面授",
      imgSrc: require("@/assets/images/train/kffj.png")
    };
  }
});
const imgUrl = computed(() => {
  let url = require("@/assets/images/trainDetail/hly.png");
  if (type == "1") {
    url = require("@/assets/images/trainDetail/pgs.png");
  } else if (type == "2") {
    url = require("@/assets/images/trainDetail/hly.png");
  } else {
    url = require("@/assets/images/trainDetail/zxs.png");
  }
  return url;
});
</script>

<style lang="less" scoped>
.trainDetail {
  margin-top: 40px;
  .detailInfo {
    padding: 30px 40px;
    background-color: #fff;
    box-sizing: border-box;
    position: relative;
    .infoBox {
      width: 100%;
      padding: 40px 60px;
      box-sizing: border-box;
      background: #f5f5f5;
      border-radius: 7px;
      display: flex;
      img {
        width: 500px;
        height: 320px;
        display: inline-block;
        margin-right: 40px;
      }
      .infoRight {
        .infoTitle {
          font-size: 34px;
          color: #333333;
        }
        .infoCenter {
          margin-top: 20px;
          span {
            background: #f88a8a;
            border-radius: 18px;
            padding: 6px 20px;
            box-sizing: border-box;
            font-size: 16px;
            color: #ffffff;
            cursor: pointer;
          }
        }
        .infoRigtBot {
          .rightItem {
            font-size: 22px;
            font-weight: bold;
            margin-top: 40px;
            span:first-child {
              color: #dc3434;
            }
            span:last-child {
            }
          }
        }
      }
    }
    .btn{
      width: 160px;
      height: 60px;
      background-color: #dc3434;
      position: absolute;
      bottom: 80px;
      right: 80px;
      text-align: center;
      line-height: 60px;
      color: #fff;
      border-radius: 10px;
      font-size: 22px;
      &:hover{
        cursor: pointer;
        transform: scale(1.1);
        transition: all .3s;
      }
    }
  }
}
</style>
